import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CreateTokenResponse,
  StatusFingerPrintRequest,
  UseFingerPrintRequest,
  UserLoggedIn
} from './dashboard/UserLoggedIn.model';
import { TokenGenerationResponse, ValidateEmailResponse } from '../login/models/login.model';
import {
  AddPaymentResponse,
  PaymentMethod,
  PaymentTypeRequest,
  RemovePaymentResponse,
} from './models/Payments';
import { MxUrlRequest } from './models/MxUrlRequest';
import { AddMXTokenRequest, MxLogData } from './models/AddMXTokenRequest';
import { Contact } from './models/Contact.models';
import { Activity, ActivityPaged } from './models/Activity.models';
import { UpdateUserData } from '../profile/models/UpdateUserData';
import { RequestFundsRequest } from './models/RequestFundsRequest.models';
import { SendFundsModelRequest } from './models/SendFunds.models';
import { CreateReferral } from './models/CreateReferral.models';
import { LookupNamesByPhone } from './models/LookupNamesByPhone.models';
import { updateACHNicknameRequest } from './models/updateACHNicknameRequest';
import { ItemCountResponse } from './models/ItemCountResponse.models';
import { IdentityVerificationRequest } from './models/IdentityVerificationRequest';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private p2pServerUrl = `${environment.p2pUrl}api-p2p/`;
  constructor(private http: HttpClient) { }

  getNewToken(): Observable<TokenGenerationResponse> {
    const url = `${this.p2pServerUrl}security/refresh-token`;
    return this.http.get<TokenGenerationResponse>(url);
  }

  setStatusBiometricLogin(request: StatusFingerPrintRequest): Observable<boolean> {
    const url = `${this.p2pServerUrl}account/set-fingerprint-status`;
    return this.http.post<boolean>(url, request);
  }

  registerToken(request: UseFingerPrintRequest): Observable<boolean> {
    const url = `${this.p2pServerUrl}account/use-fingerprint`;
    return this.http.post<boolean>(url, request);
  }

  MXLogData(data: MxLogData): Observable<string> {
    const url = `${this.p2pServerUrl}account/mx-logdata`;
    return this.http.post<string>(url, data);
  }

  AddMXToken(data: AddMXTokenRequest): Observable<string> {
    const url = `${this.p2pServerUrl}account/add-mxtoken`;
    return this.http.post<string>(url, data);
  }

  createToken(alias: string): Observable<CreateTokenResponse> {
    const url = `${this.p2pServerUrl}security/create-token?alias=${alias}`;
    return this.http.get<CreateTokenResponse>(url);
  }

  getUser(): Observable<UserLoggedIn> {
    const url = `${this.p2pServerUrl}security/user-loggedin`;
    return this.http.get<UserLoggedIn>(url);
  }

  getBankListUrl(data: MxUrlRequest): Observable<string> {
    const url = `${this.p2pServerUrl}get-mx-url`;
    return this.http.post<string>(url, data);
  }

  savePaymentMethod(data: PaymentTypeRequest): Observable<AddPaymentResponse> {
    const url = `${this.p2pServerUrl}account/payment-method`;
    return this.http.post<AddPaymentResponse>(url, data);
  }

  updatePaymentMethod(data: PaymentTypeRequest): Observable<AddPaymentResponse> {
    const url = `${this.p2pServerUrl}account/payment-method`;
    return this.http.put<AddPaymentResponse>(url, data);
  }

  getContacts(): Observable<Contact[]> {
    const url = `${this.p2pServerUrl}contact/`;
    return this.http.get<Contact[]>(url);
  }

  getTopContacts(): Observable<Contact[]> {
    const url = `${this.p2pServerUrl}contact/top?rows=8`;
    return this.http.get<Contact[]>(url);
  }

  getContactByPhoneNumber(phoneNumber: string): Observable<Contact> {
    const url = `${this.p2pServerUrl}contact/byphonenumber?phonenumber=${phoneNumber}`;
    return this.http.get<Contact>(url);
  }

  getContactByName(firstname: string, lastname: string): Observable<Contact> {
    const url = `${this.p2pServerUrl}contact/byname?firstname=${firstname}&lastname=${lastname}`;
    return this.http.get<Contact>(url);
  }

  getActivities(consumerProfileId: string): Observable<Activity[]> {
    const url = `${this.p2pServerUrl}activity/${consumerProfileId}`;
    return this.http.get<Activity[]>(url);
  }

  getTopActivities(): Observable<Activity[]> {
    const url = `${this.p2pServerUrl}activity/top?rows=0`;
    return this.http.get<Activity[]>(url);
  }

  getDetailedActivities(pageIndex: number, pageCount: number): Observable<ActivityPaged> {
    const url = `${this.p2pServerUrl}activity/detailedactivities?pageIndex=${pageIndex}&pageCount=${pageCount}`;
    return this.http.get<ActivityPaged>(url);
  }


  getPaymentMethods(individualId: string): Observable<PaymentMethod[]> {
    const url = `${this.p2pServerUrl}account/payment-method`;
    return this.http.get<PaymentMethod[]>(url);
  }

  removePaymentMethods(individualId: string, paymentTokenId: string): Observable<RemovePaymentResponse> {
    const url = `${this.p2pServerUrl}account/payment-method?individualId=${individualId}&PaymentTokenId=${paymentTokenId}`;
    return this.http.delete<RemovePaymentResponse>(url);
  }

  removeACHToken(paymentTokenId: string): Observable<RemovePaymentResponse> {
    const url = `${this.p2pServerUrl}account/payment-method/achtoken?achTokenId=${paymentTokenId}`;
    return this.http.delete<RemovePaymentResponse>(url);
  }

  updateBasicUserData(data: UpdateUserData): Observable<any> {
    const url = `${this.p2pServerUrl}account/update-user`;
    return this.http.post<any>(url, data);
  }

  addContact(data: Contact): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/contact`;
    return this.http.post<any>(url, data);
  }

  updateContact(data: Contact): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/contact`;
    return this.http.put<any>(url, data);
  }

  deleteContact(contactId: number): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/contact/${contactId}`;
    return this.http.delete<any>(url);
  }

  requestFunds(data: RequestFundsRequest): Observable<any> {
    const url = `${environment.p2pUrl}funds/request`;
    return this.http.post<any>(url, data);
  }
  sendFunds(data: SendFundsModelRequest): Observable<any> {
    const url = `${environment.p2pUrl}funds/send`;
    return this.http.post<any>(url, data);
  }

  resendFundsRequest(requestId: string, isRequest: boolean): Observable<any> {
    const url = `${environment.p2pUrl}funds/resend?requestId=${requestId}&isRequest=${isRequest}`;
    return this.http.post<any>(url, {});
  }

  cancelFundsRequest(requestId: string): Observable<any> {
    const url = `${environment.p2pUrl}/funds/cancel?requestId=${requestId}`;
    return this.http.post(url, {});
  }

  changeProfilePicture(data: FormData): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/account/changeprofilepicture`;
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.http.post<any>(url, data, options);
  }

  removeProfilePicture(): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/account/removeprofilepicture`;
    return this.http.post<any>(url, {});
  }
  createReferral(data: CreateReferral): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/referral/create-referral`;
    return this.http.post<any>(url, data);
  }

  lookupNamesByPhone(data: LookupNamesByPhone): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/security/LookupNamesByPhone`;
    return this.http.post<boolean>(url, data);
  }

  updateACHNickname(data: updateACHNicknameRequest): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/account/updateachnickname`;
    return this.http.put<any>(url, data);
  }

  getItemCount(): Observable<ItemCountResponse> {
    const url = `${environment.p2pUrl}api-p2p/account/item-count`;
    return this.http.get<any>(url);
  }

  resendEmailConfirmationCode(): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/security/resend-email-confirmation-code`;
    return this.http.post<boolean>(url, {});
  }

  validateEmail(code: string): Observable<ValidateEmailResponse> {
    const url = `${environment.p2pUrl}api-p2p/security/validate-email?code=${code}`;
    return this.http.post<ValidateEmailResponse>(url, {});
  }

  validateIdentity(data: FormData): Observable<string> {

    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };

    const url = `${environment.p2pUrl}api-p2p/security/identity-verification`;
    return this.http.post<any>(url, data, options);
  }

  changeEmail(data: any): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/account/change-email`;
    return this.http.post<any>(url, data);
  }

  changePhoneNumber(data: any): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/account/change-phonenumber`;
    return this.http.post<any>(url, data);
  }

  changePassword(data: any): Observable<any> {
    const url = `${environment.p2pUrl}api-p2p/account/password`;
    return this.http.post<any>(url, data);
  }
}
