// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {DefaultPrivacyLevel} from "@datadog/browser-rum";

export const environment = {
  production: false,
  apiKey: 'everyware:public:7dbfe999c37f4a8dab7bb6373833bee4',
  p2pUrl: 'https://p2p-dev.everyware.com/',
  passwordLessApiUrl: 'https://v4.passwordless.dev',
  reCaptchaSiteKey: '6LdKBhEjAAAAALZTin7vzGT8etRbzlHZHbgrO98g',
  datadogRum: {
    applicationId: '1f47e9f0-12e3-43f1-af28-92436bbfefd1',
    clientToken: 'pubc678c9169e6076afc25cacac2f1debca',
    env: 'Test',
    site: 'us3.datadoghq.com',
    service: 'p2p-site-dev',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    silentMultipleInit: true
  },
  datadogLogs: {
    clientToken: 'pubc678c9169e6076afc25cacac2f1debca',
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
