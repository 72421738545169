import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { LocalStorage } from '../classes/local-storage';
import { AccountService } from '../../account/account.service';
import { Router } from '@angular/router';
import { TokenGenerationResponse } from '../../login/models/login.model';

@Injectable()
export class AppAuthInterceptor implements HttpInterceptor {

  tokenResponse:string = "";
  localStorage = new LocalStorage();

  constructor(private service: AccountService,
              private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const bearerToken = this.localStorage.getBearerToken();
    if (!bearerToken){
      return next.handle(request)
        .pipe(
          catchError((err: HttpErrorResponse) => {
            this.router.navigate(['security/signing']);
            return throwError(() => err);
          })
        );
    }

    this.tokenResponse = bearerToken;

    let req = request;

    if (this.tokenResponse !== '') {
      req = request.clone({
        setHeaders: {
          authorization: `Bearer ${ this.tokenResponse }`
        }
      });
    }


    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
      if(err.status === 401) {
        const storage = new LocalStorage();
        storage.removeBearerToken();
        this.router.navigate(['security/signing']);
      }
      console.log(err)
      return throwError(() => err);
    }));
  }
}
