import { FingerPrint } from './FingerPrint';

export class LocalStorage {

  private tokenKey = 'token';
  private useFingerprint = 'useFingerPrint';

  activateFingerPrint(email: string, userId: number) {
    const fingerprint: FingerPrint = { useFingerprint: true, email: email, userId: userId }
    localStorage.setItem(this.useFingerprint, JSON.stringify(fingerprint));
  }

  inactivateFingerPrint() {
    const json = localStorage.getItem(this.useFingerprint)
    if(!json){
      localStorage.setItem(this.useFingerprint, JSON.stringify({ email: '', userId: 0, useFingerprint: false }));
    } else {
      const fingerprint: FingerPrint = JSON.parse(json);
      fingerprint.useFingerprint = false;

      localStorage.setItem(this.useFingerprint, JSON.stringify(fingerprint));
    }
  }

  getUseFingerPrint(): FingerPrint {
    const json = localStorage.getItem(this.useFingerprint)
    if(!json)
      return { email: '', userId: 0, useFingerprint: false };

    const fingerprint: FingerPrint = JSON.parse(json);
    return fingerprint;
  }

  removeUseFingerPrint() {
    localStorage.removeItem(this.useFingerprint);
  }

  saveBearerToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  getBearerToken(): string {
    return localStorage.getItem(this.tokenKey) || '';
  }

  removeBearerToken(): void {
    localStorage.removeItem(this.tokenKey);
  }

  setItem(name: string, value: string) {
    localStorage.setItem(name, value);
  }
  getItem(name: string) {
    return localStorage.getItem(name)
  }
  removeItem(name: string) {
    localStorage.removeItem(name);
  }
}
